import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  animations: [
    trigger('gotoTopAnimation', [
      state('small', style({
        transform: 'scale(1)',
      })),
      state('large', style({
        transform: 'scale(1.2)',
      })),
      transition('small => large', animate('100ms ease-in')),
    ]),
  ],
})
export class AppLayoutComponent implements OnInit {

  isShow: boolean;
  topPosToStartShowing = 10;
  state: string;

  constructor() {
    this.state = 'small';
  }

  ngOnInit(): void {
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  scrollToTop() {
    this.state = (this.state === 'small' ? 'large' : 'small'); // animateMe
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.closeAnimation();
  }

  closeAnimation() {
    setTimeout(() => {
      this.state = 'small';
    }, 1000);
  }
}
