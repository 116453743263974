import { Component, OnInit, ChangeDetectorRef, HostListener, Input, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnInit, AfterViewInit {
  @Input() imagesList: any;
  @Input() index: any;
  activeSlideIndex: number;
  details: any;

  constructor(
    public modalRef: BsModalRef,
    private cdr: ChangeDetectorRef) {
    }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.slideChanged(this.index);
  }

  getDetails(activeSlideIndex: any) {
    this.imagesList.forEach((element: any, idx: number) => {
      if (activeSlideIndex === idx) {
        this.activeSlideIndex = idx;
        this.details = element;
        console.log(this.details);
      }
    });
    this.cdr.detectChanges();
  }
  
  /**
   * @name slideChanged
   * @description Change carousel slides
   */
  slideChanged(idx: number) {
    this.activeSlideIndex = idx;
    this.getDetails(this.activeSlideIndex);
  }

  increment() {
    if (this.activeSlideIndex < this.imagesList.length - 1) {
      this.activeSlideIndex++;
      this.getDetails(this.activeSlideIndex);
    }
  }

  decrement() {
    if (this.activeSlideIndex > 0) {
      this.activeSlideIndex--;
      this.getDetails(this.activeSlideIndex);
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event);

    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.increment();
    }

    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.decrement();
    }
  }

}
