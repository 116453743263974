import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './app-layout.component';
import { AboutModule } from 'src/app/pages/about/about.module';
import { ContactModule } from 'src/app/pages/contact/contact.module';
import { HomeModule } from 'src/app/pages/home/home.module';
import { PortfolioModule } from 'src/app/pages/portfolio/portfolio.module';
import { ServicesModule } from 'src/app/pages/services/services.module';
import { FooterModule } from '../footer/footer.module';
import { HeaderModule } from '../header/header.module';

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent
  }
];

@NgModule({
  declarations: [AppLayoutComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FooterModule,
    HeaderModule,
    PortfolioModule,
    ServicesModule,
    ContactModule,
    AboutModule,
    HomeModule,
  ]
})
export class AppLayoutModule { }
