import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'CleanSite';

  ngOnInit(): void {
    // redirect to new domain
    if (window.location.href.indexOf("gebaudereinigung") > -1) {
      window.location.href = "https://www.gerai-gebäudereinigung.de";
    }
  }
}
