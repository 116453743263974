import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-hide-text',
  templateUrl: './hide-text.component.html',
  styleUrls: ['./hide-text.component.scss']
})
export class HideTextComponent implements OnInit {
  seeMore = false;

  constructor() { }

  ngOnInit() {
  }

}
