import { Component, OnInit } from '@angular/core';
import { ImagePreviewComponent } from 'src/app/shared/image-preview/image-preview/image-preview.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {
  portfolioList = [];
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService) { }

  ngOnInit() {
    this.portfolioList = [
      {
        portfolioImage: './assets/portfolio/1.jpeg',
        portfolioInfo: ''
      },
      {
        portfolioImage: './assets/portfolio/2.jpeg',
        portfolioInfo: '',
      },
      {
        portfolioImage: './assets/portfolio/3.jpeg',
        portfolioInfo: ''
      },
      {
        portfolioImage: './assets/portfolio/4.jpeg',
        portfolioInfo: ''
      },
      {
        portfolioImage: './assets/portfolio/5.jpeg',
        portfolioInfo: ''
      },
      {
        portfolioImage: './assets/portfolio/6.jpeg',
        portfolioInfo: ''
      },
      {
        portfolioImage: './assets/portfolio/7.jpeg',
        portfolioInfo: ''
      },
      {
        portfolioImage: './assets/portfolio/8.jpeg',
        portfolioInfo: ''
      },
      {
        portfolioImage: './assets/portfolio/9.jpeg',
        portfolioInfo: ''
      },
      {
        portfolioImage: './assets/portfolio/10.jpeg',
        portfolioInfo: ''
      },
    ];
  }


  openImagePreview(index: any) {
    this.modalRef = this.modalService.show(ImagePreviewComponent,
      {
        class: 'modal-dialog-centered modal-lg',
        initialState: {
          index,
          imagesList: this.portfolioList
        }
    });
  }

}
