import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  year = new Date().getFullYear() - 2015;

  servicesList = [
    {
      icon: './assets/services/office-icon.png',
      alt: 'office',
      title: 'Gebäudereinigung',
      mainParagraph: 'Leistungsüberblick:',
      list: [
        'Büroreinigung',
        'Reinigung von Privathaushalten',
        'Treppenhausreinigung',
        'Parkett- und Laminatreinigung',
        'Steinböden- und Fliesenreinigung'
      ],
      footer: ''
    },
    {
      icon: './assets/services/house-icon.png',
      alt: 'house',
      title: 'Kehrwoche',
      mainParagraph: 'Leistungsüberblick:',
      list: [
        'Kehrwoche im Außenbereich',
        'Garagen- und Tiefgaragenreinigung',
        'BIRCO-Rinnen, Siebe und Abflüsse reinigen',
        'Unkrautbeseitigung auf Wegen',
      ],
      footer: ''
    },
    {
      icon: './assets/services/window-icon.png',
      alt: 'window',
      title: 'Fensterreinigung',
      mainParagraph: 'Leistungsüberblick:',
      list: [
        'Reinigung von Fenstern und Türen mit Rahmen',
        'Reinigung von Glasdächern und Wintergärten',
        'Reinigung von Oberlichtern',
        'Fassadenreinigung'
      ],
      footer: ''
    },
    {
      icon: './assets/services/garden-icon.png',
      alt: 'garden',
      title: 'Gartenpflege',
      mainParagraph: 'Leistungsüberblick: ',
      list: [
        'Gehölz- und Baumpflege',
        'Rasenmähen',
        'Jahrespflege von Grünanlagen'
      ],
      footer: ''
    },
    {
      icon: './assets/services/bucket-icon.png',
      alt: 'bucket',
      title: 'Hausmeisterservice',
      mainParagraph: 'Leistungsüberblick:',
      list: [
        'Innenreinigung',
        'Kehrwoche/Winterdienst',
        'Fensterreinigung',
        'Gartenpflege',
        'Kleinreparaturen'
      ],
      footer:''
    },
    {
      icon: './assets/services/shovel-icon.png',
      alt: 'shovel',
      title: 'Winterdienst',
      mainParagraph: 'Leistungsüberblick:',
      list: [
        'Winterdienst;',
        'Räumung und Streuung von Gehwegen, Treppen und Parkplätzen mit dem Motorbesen bzw. der Schneeschaufel;',
        '7 Tage die Woche;',
        'Saison vom 01.11. bis einschließlich 31.03.',
        'Verrechnung nach Einsatz oder pauschale.'
      ],
      footer: ''
    },
  ];


  constructor() { }

  ngOnInit() {
  }

}
