import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagePreviewComponent } from './image-preview.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';

@NgModule({
  declarations: [ImagePreviewComponent],
  exports: [ImagePreviewComponent],
  entryComponents: [ImagePreviewComponent],
  imports: [
    CommonModule,
    CarouselModule.forRoot(),
  ]
})
export class ImagePreviewModule { }
