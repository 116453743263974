import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  id = 'intro';
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService) { }

  ngOnInit() {
  }

  addClass(id: string) {
    this.id = id;
    const elem = document.querySelector('.show');
    if (elem != null) {
      elem.classList.remove('show');
      elem.classList.add('collapse');
    }
  }

  openPhoneCall(content: TemplateRef<any>) {
    this.modalRef = this.modalService.show(content, { class: 'modal-dialog-centered modal-sm'});
  }
}
