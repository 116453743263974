import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortfolioComponent } from './portfolio.component';
import { ImagePreviewModule } from 'src/app/shared/image-preview/image-preview/image-preview.module';

@NgModule({
    imports: [
      CommonModule,
      ImagePreviewModule
    ],
    declarations: [PortfolioComponent],
    exports: [PortfolioComponent]
})
export class PortfolioModule { }
