import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesComponent } from './services.component';
import { HideTextModule } from 'src/app/shared/hide-text/hide-text.module';

@NgModule({
    imports: [
      CommonModule,
      HideTextModule
    ],
    declarations: [ServicesComponent],
    exports: [ServicesComponent]
})
export class ServicesModule { }
