import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AppLayoutComponent } from './core/app-layout/app-layout.component';

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
};

const routes: Routes = [
  {
    path: 'privacy-and-policy',
    loadChildren: () => import('./pages/privacy-and-policy/privacy-and-policy.module').then(m => m.PrivacyAndPolicyModule)
  },
  {
    path: '',
    component: AppLayoutComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
