import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HideTextComponent } from './hide-text.component';

@NgModule({
  exports: [HideTextComponent],
  declarations: [HideTextComponent],
  imports: [
    CommonModule
  ]
})
export class HideTextModule { }
